import Image from 'next/image';

import { ChevronDown } from '@/icons';

import type { FunctionComponent } from 'react';
import type { TypeAdvantage, TypeAdvantagesProps } from './types';

// TODO añadir casos extremos con más de 5 elementos (slide)
export const Advantages: FunctionComponent<TypeAdvantagesProps> = ({
  className = '',
  title,
  advantages,
}: TypeAdvantagesProps) => {
  if (
    title === undefined &&
    (advantages === undefined || advantages?.length === 0)
  )
    return null;

  const listOfAdvantages = advantages?.map(
    (advantage: TypeAdvantage, index: number) => (
      <div
        className="flex items-center gap-4 border-b border-dashed border-typo-primary py-6 first:border-t lg:border-none lg:py-0"
        key={`${advantage.title}_${index}`}
      >
        {advantage.icon && advantage.icon.src && (
          <Image
            src={advantage.icon.src}
            width={50}
            height={50}
            alt={advantage.icon.alt || advantage.title || ''}
            className="flex-shrink-0"
          />
        )}

        <div>
          {advantage.title && (
            <span className="u-headline u-headline--h4 md:max-w-52">
              {advantage.title}
            </span>
          )}

          {advantage.copy && (
            <div
              className="u-body u-body--xs text-typo-secondary md:max-w-52 [&_a]:normal-case [&_a]:text-secondary-400"
              dangerouslySetInnerHTML={{ __html: advantage.copy }}
            />
          )}
        </div>
      </div>
    ),
  );

  return (
    <div className={`relative ${className}`}>
      <div className="relative h-3">
        <div className="absolute left-0 right-0 top-px border-t-[12px] border-transparent bg-[url('/assets/images/paper-texture-top.svg')] content-['']" />
      </div>

      <div className="bg-bg-secondary px-6 py-3 lg:flex-row lg:justify-between xl:gap-8">
        <details className="group lg:hidden">
          <summary className="u-headline u-headline--h3 flex w-full cursor-pointer select-none justify-between self-center text-left focus:outline-none lg:pointer-events-none lg:hidden lg:w-auto lg:max-w-52 [&::-webkit-details-marker]:hidden">
            <span>{title}</span>
            <ChevronDown className="h-6 w-6 text-typo-tertiary transition-transform group-open:rotate-180 lg:hidden" />
          </summary>

          <div className="mt-6">{listOfAdvantages}</div>
        </details>

        <div className="hidden lg:grid lg:grid-cols-4">{listOfAdvantages}</div>
      </div>

      <div className="relative h-3">
        <div className="absolute bottom-px left-0 right-0 border-b-[12px] border-transparent bg-[url('/assets/images/paper-texture-bottom.svg')] content-['']" />
      </div>
    </div>
  );
};
